import { Link } from 'gatsby'
import React, { useState } from 'react';
import Logo from './Logo';

function Navbar() {
  const [active, setActive] = useState(false);

  const toggleHamburger = () => {
    setActive(prevActive => !prevActive);
  };

  return (
    <nav
      className="navbar is-transparent"
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" title="Logo">
            <Logo size={64} />
          </Link>
          {/* Hamburger menu */}
          <div
            role="button"
            aria-label="menu"
            aria-expanded={active ? 'true' : 'false'}
            className={`navbar-burger burger ${active && 'is-active'}`}
            data-target="navMenu"
            onClick={() => toggleHamburger()}
            tabIndex={0}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </div>
        </div>
        <div
          id="navMenu"
          className={`navbar-menu ${active && 'is-active'}`}
        >
          <div className="navbar-start has-text-centered">
            <Link className="navbar-item" to="/about">
              About
            </Link>
            <Link className="navbar-item" to="/blog">
              Articles
            </Link>
            <Link className="navbar-item" to="/contact">
              Contact
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
